import { Injectable } from '@angular/core';
// import { ParamServicesService } from './param-services.service';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  deferredPrompt = undefined;
  private _backbutton = false;

  get backbutton() {
    return this._backbutton;
  }

  set backbutton(value) {
    this._backbutton = value;
  }
}
