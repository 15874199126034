import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcAdditionalSubtotal'
})
export class CalcAdditionalSubtotalPipe implements PipeTransform {

  transform( value:any[], productQuantity ): number {
    const optionsPrice = value.reduce((acc, next) => { 
      return acc += next.price;
    }, 0)
    return optionsPrice * productQuantity;
  }

}
