import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixStyle'
})
export class FixStylePipe implements PipeTransform {

  transform(value: any): string {
    const fixTitle: string = value.title.toLocaleLowerCase().replace(/ /g, "_");
    const style = `var(--${value.type}-${fixTitle})`;
    return style;
  }

}
