import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { UiServices } from './ui-services';
import { ChangeLanguageComponent } from '../generales-components/change-language/change-language.component';
import { ParamServicesService } from './param-services.service';
import { StorageService } from './storage.service';

interface Language {
  id: number;
  icons: string;
  default?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LangService {
  private _langage: string = 'es';
  private _langages: string[] = undefined;
  private _domainId = environment.domainId;
  private _selectedLang: Language;

  set selectedLang(value) {
    this._selectedLang = value;
  }

  get selectedLang() {
    return this._selectedLang;
  }

  private _languagesSv: Language[] = [];

  public langNames = {
    es: 'Español',
    en: 'Inglés',
  };

  get langage() {
    return this._langage;
  }

  set langage(value) {
    this._langage = value;
  }

  get langages() {
    return this._langages;
  }

  set langages(value) {
    this._langages = value;
  }

  constructor(
    private translateService: TranslateService,
    private httpSv: HttpService,
    private _uiSv: UiServices,
    private _paramSv: ParamServicesService,
    private _storageSv: StorageService
  ) {
    const cacheLang = this._storageSv.getLocalStorage('saved_lang');
    if (cacheLang) {
      const parsendLang = JSON.parse(cacheLang);
      this._selectedLang = parsendLang;
    }
  }

  async initLanguage() {
    try {
      const params = {
        domainId: this._domainId,
      };
      const resLangs = await this.httpSv.itemIndex('language', '', params);
      this._languagesSv = resLangs.data;
      if (
        !this._storageSv.getLocalStorage('saved_lang') &&
        this._languagesSv.length
      ) {
        this.setLang(
          this._languagesSv.find((x) => x.default),
          false
        );
      }
      const availableLangs: any[] = await this.httpSv.itemIndex(
        'client',
        '',
        '',
        'translate'
      );

      const mappedLangs = availableLangs.map((x) => x.name);
      const addLangs = mappedLangs.length ? mappedLangs : ['es', 'en'];
      const data = await this.httpSv.getParam('lang', this._domainId);
      const lang = data ? JSON.parse(data.value) : 'es';

      this.translateService.addLangs(addLangs);
      this.langages = this.translateService.getLangs();
      this.translateService.translations;
      this.translateService.setDefaultLang(lang);
      this.translateService.use(lang);
      return true;
    } catch (err) {
      console.error(err);
    }
  }

  openLanguagePopover(ev) {
    this._uiSv.showPopover(
      ChangeLanguageComponent,
      {
        langs: this._languagesSv,
      },
      '',
      ev
    );
  }

  setLang(lang: Language, reload = true) {
    this._selectedLang = lang;
    this._storageSv.setLocalStorage(
      'saved_lang',
      JSON.stringify(this._selectedLang)
    );
    if (reload) {
      this._paramSv.reload.emit();
    }
  }

  async changeLangage(langage: string) {
    const useLangage = langage || 'es';
    this.translateService.use(useLangage);
    localStorage.setItem('langage', langage);
    this.langage = langage;
    await this.httpSv.itemAction({ lang: langage }, 'auth', 'lang');
  }
}
