import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'typeProduct'
})
export class TypeProductPipe implements PipeTransform {

  constructor(
    private trSv: TranslateService
  ){}

  transform(item: any): string {
    const frequency = item?.frequency || item?.product?.frequency;
    if(frequency && item.inscriptionPrice){
      return `${this.trSv.instant('INSCRIPCION')}: `;
    }else if(frequency){
      return `${frequency}: `;
    }else{
      return `${this.trSv.instant('PRECIO')}: `;
    }
  }

}
