import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const apiAddress = environment.url + `/file/translate/${lang}.json?a=1`;

    return this.httpClient.get(apiAddress).pipe(
      map((response: JSON) => {
        return response;
      }),
      catchError((_) => {
        console.error('Error');
        return this.httpClient.get(`/assets/i18n/${lang}.json`);
      })
    );
  }
}
