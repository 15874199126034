import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'router',
})
export class RouterPipe implements PipeTransform {
  constructor() {}

  transform(product): string {
    let route = '';
    route = `/product-detail/${product.id}`;
    return route;
    if (product.duration) {
      route = `/meeting/${product.id}`;
    } else {
      route = `/product-detail/${product.id}`;
    }

    return route;
  }
}
