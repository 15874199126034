import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UiServices } from './ui-services';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class VersionControllerService {
  private _available = false;

  constructor(
    private uiSv: UiServices,
    private trSv: TranslateService,
    private swUpdate: SwUpdate,
    private platform: Platform,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  async verify() {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }

    if (!this.platform.is('capacitor')) {
      this.swUpdate.versionUpdates.subscribe(async (ev) => {
        if (!this._available && ev.type === 'VERSION_READY') {
          this._available = true;
          const msg = this.trSv.instant(
            'HAY_UNA_NUEVA_VERSION_DISPONIBLE_HAZ_CLIC_EN_CONTINUAR_PARA_ACTUALIZAR'
          );
          const { role } = await this.uiSv.presentAlert(
            msg,
            true,
            'CONTINUAR',
            false,
            'CANCELAR'
          );
          if (role === 'accept') {
            window.location.reload();
          }
        }
      });
    }
  }
}
